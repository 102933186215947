import React, { createContext, useContext, useState } from 'react';

const SelectedPostContext = createContext();

export const SelectedPostProvider = ({ children }) => {
    const[markerId,setMarkerId]=useState(null); 

  return (
    <SelectedPostContext.Provider value={{ markerId, setMarkerId }}>
      {children}
    </SelectedPostContext.Provider>
  );
};

export const useSelectedPost = () => {
  return useContext(SelectedPostContext);
};
