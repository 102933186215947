// src/Components/FilterComponent.js

import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFilters } from "./FilterContext";
import "./FilterComponent.css";
import { FaCalendarAlt } from "react-icons/fa";

const FilterComponent = () => {
  const {
    selectedKeyword,
    setSelectedKeyword,
    selectedParty,
    setSelectedParty,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedMediaType,
    setSelectedMediaType,
  } = useFilters();

  const [keywords, setKeywords] = useState([]);
  const [executingParties, setExecutingParties] = useState([]);

  // Fetch keywords from the API
  useEffect(() => {
    fetch("https://gazamap-backend.cogdevelopers.com/api/keywords")
      .then((response) => response.json())
      .then((data) => {
        setKeywords(
          data.map((keyword) => ({
            value: keyword.id,
            label: keyword.keyword,
          }))
        );
      })
      .catch((error) => console.error("Error fetching keywords:", error));
  }, []);

  // Fetch executing parties from the API
  useEffect(() => {
    fetch("https://gazamap-backend.cogdevelopers.com/api/executing-parties")
      .then((response) => response.json())
      .then((data) => {
        setExecutingParties(
          data.map((party) => ({
            value: party.id,
            label: party.name,
          }))
        );
      })
      .catch((error) =>
        console.error("Error fetching executing parties:", error)
      );
  }, []);

  const handleKeywordChange = (selectedOption) => {
    setSelectedKeyword(selectedOption ? selectedOption.value : null);
  };

  const handlePartyChange = (selectedOption) => {
    setSelectedParty(selectedOption ? selectedOption.value : null);
  };
  const handleMediaTypeChange = (selectedOption) => {
    setSelectedMediaType(selectedOption ? selectedOption.value : null); // Handle media type change
  };
  const mediaTypeOptions = [
    { value: "vid", label: "Video" },
    { value: "pic", label: "Picture" },
    { value: "both", label: "Both" },
  ];

  const CustomInput = ({ value, onClick }) => (
    <div className="custom-date-picker">
      <input
        value={value}
        onClick={onClick}
        placeholder="mm/dd/yyyy"
        className="date-input"
      />
      {!value && <FaCalendarAlt className="calendar-icon" />}
    </div>
  );

  return (
    <div className="filter-component">
      <Select
        value={
          keywords.find((keyword) => keyword.value === selectedKeyword) || null
        }
        options={keywords}
        onChange={handleKeywordChange}
        placeholder="نوع العمل"
        isClearable
        className="select-keyword"
        onMouseDown={(e) => e.stopPropagation()}
      />

      <Select
        value={
          executingParties.find((party) => party.value === selectedParty) ||
          null
        }
        options={executingParties}
        onChange={handlePartyChange}
        placeholder="الجهة المنفذة"
        isClearable
        className="select-executing-party"
        onMouseDown={(e) => e.stopPropagation()}
      />

      <Select
        value={
          mediaTypeOptions.find(
            (option) => option.value === selectedMediaType
          ) || null
        }
        options={mediaTypeOptions}
        onChange={handleMediaTypeChange}
        placeholder="Select Media Type"
        isClearable
        className="select-media-type"
        onMouseDown={(e) => e.stopPropagation()}
      />
      <h3>تاريخ النشر</h3>
      <div className="date-picker">
        <label>Start Date</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          isClearable
          placeholderText="Select Start Date"
          customInput={<CustomInput />}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
      <div className="date-picker">
        <label>End Date</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          isClearable
          placeholderText="Select End Date"
          customInput={<CustomInput />}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
