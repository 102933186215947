import React, { useState, useEffect } from 'react';
import { useSearch } from './SearchContext';
import './SearchBar.css';

const SearchBar = () => {
  const { setSearchTerm } = useSearch(); // Access the setter from the context
  const [inputValue, setInputValue] = useState('');
  
  // Debounce mechanism
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(inputValue);
    }, 500); // 300ms delay

    // Cleanup the timeout if inputValue changes before the delay
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, setSearchTerm]);

  return (
    <div className="search-bar">
      <input
        type="text"
        className="search-input"
        placeholder="Search..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onMouseDown={(e) => e.stopPropagation()} 
      />
      
      <span className="search-icon">
        🔍
      </span>
    </div>
  );
};

export default SearchBar;
