import React from 'react';
import MapComponent from './Components/MapComponent';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Components/Sidebar';
import { SearchProvider } from "./Components/SearchContext";
import { SelectedPostProvider } from "./Components/SelectedPostContext";
import { FilterProvider } from "./Components/FilterContext";




const App = () => {
  return (
    <div>
     <SearchProvider>
      <SelectedPostProvider>
      <FilterProvider>
      
        <MapComponent  />
        </FilterProvider>
        </SelectedPostProvider>
      </SearchProvider>
       
      
    </div>
  );
};

export default App;
