import React, { useEffect, useState, useRef } from "react";
import "./Sidebar.css";
import { useSearch } from "./SearchContext";
import SearchBar from "./SearchBar";
import { useSelectedPost } from "./SelectedPostContext";
import { setMarkerId } from "./SelectedPostContext";
import FilterComponent from "./FilterComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FaNewspaper,
  FaFilter,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";

const Sidebar = ({
  selectedKeyword,
  selectedParty,
  selectedPostId,
  startDate,
  endDate,
  selectedMediaType,
  onRemoveSelectedPost,
}) => {
  const { setMarkerId } = useSelectedPost();
    const isMobile = useMediaQuery("(max-width:768px)");
  const baseUrl = "https://gazamap-backend.cogdevelopers.com";
  const { searchTerm } = useSearch();
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("posts");

  const [isSidebarVisible, setIsSidebarVisible] = useState(
    !isMobile
  );
  const sidebarRef = useRef(null);
  const selectedPostRef = useRef(null);

  const handleRemoveSelectedPost = () => {
    if (onRemoveSelectedPost) {
      onRemoveSelectedPost();
    }
    setSelectedPost(null);
  };

  const toggleView = (viewName) => {
    setView(viewName);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };


  
  

  useEffect(() => {
    if (selectedPostRef.current) {
      const sidebar = sidebarRef.current;
      const selectedElement = selectedPostRef.current;
      const elementTop = selectedElement.offsetTop;
      const offset = sidebar.clientHeight * 0.2;
      sidebar.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });
    }
    setIsSidebarVisible(true);
  }, [selectedPost]);

  const handleMarkerClick = (id) => {
    setMarkerId(id);
    setIsSidebarVisible(false);
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm || !text) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    if (selectedPostId) {
      fetch(
        `https://gazamap-backend.cogdevelopers.com/api/gaza-post-id/${selectedPostId}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSelectedPost(data);
        })
        .catch((error) => console.error("Error fetching the data:", error));
    }
  }, [selectedPostId]);

  const fetchPosts = async (pageNumber = 1, shouldReset = false) => {
    setIsLoading(true);
    let url = `https://gazamap-backend.cogdevelopers.com/api/gaza-posts?page=${pageNumber}`;

    if (selectedKeyword) {
      url += `&keyword_id=${selectedKeyword}`;
    }
    if (selectedParty) {
      url += `&executing_party_id=${selectedParty}`;
    }

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (startDate) {
      url += `&start_date=${startDate.toISOString().split("T")[0]}`;
    }

    if (endDate) {
      url += `&end_date=${endDate.toISOString().split("T")[0]}`;
    }

    if (selectedMediaType) {
      url += `&media_type=${selectedMediaType}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.data.length > 0) {
        setPosts((prevPosts) =>
          shouldReset ? data.data : [...prevPosts, ...data.data]
        );
        setHasMore(data.current_page < data.last_page);
      } else {
        setHasMore(false);
        if (shouldReset) {
          setPosts([]);
        }
      }
    } catch (error) {
      console.error("Error fetching the data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPage(1);
    fetchPosts(1, true);
    setSelectedPost(null);
  }, [
    selectedKeyword,
    selectedParty,
    searchTerm,
    startDate,
    endDate,
    selectedMediaType,
  ]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (page > 1) fetchPosts(page);
  }, [page]);

  return (
    <>
      <button
        className={`toggle-button ${isSidebarVisible ? "show" : "hide"}`}
        onClick={toggleSidebar}
      >
        {isSidebarVisible ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
      </button>
      <div
        className={`sidebar ${isSidebarVisible ? "show" : "hide"}`}
        ref={sidebarRef}
      >
        <div className="sidebar-header">
          <button
            className={`sidebar-button ${view === "posts" ? "active" : ""}`}
            onClick={() => toggleView("posts")}
          >
            <FaNewspaper className="icon" /> News
          </button>
          <button
            className={`sidebar-button ${view === "filters" ? "active" : ""}`}
            onClick={() => toggleView("filters")}
          >
            <FaFilter className="icon" /> Filters
          </button>
        </div>
        <div>
          {view === "posts" && (
            <div className="sidebar-container">
              <SearchBar />
              {selectedPost && (
                <div
                  className="sidebar-item-selected "
                  key={selectedPost.ID}
                  ref={selectedPostRef}
                >
                  <span
                    className="material-symbols-outlined remove-button"
                    onClick={handleRemoveSelectedPost}
                  >
                    close
                  </span>
                  <div className="header">
                    <span className="place">
                      {highlightText(selectedPost.Place, searchTerm)}
                    </span>
                    |
                    <span className="keyword">
                      {highlightText(selectedPost.Keyword, searchTerm)}
                    </span>
                  </div>
                  <div className="executing-party">
                    {highlightText(selectedPost.ExecutingParty, searchTerm)}
                  </div>
                  <div className="operation-description">
                    {highlightText(
                      selectedPost.OperationDescription,
                      searchTerm
                    )}
                  </div>
                  <div className="publication-date">
                    {highlightText(selectedPost.PublicationDate, searchTerm)}
                  </div>
                  {selectedPost.photo_url && (
                    <img
                      src={`${baseUrl}${selectedPost.photo_url}`}
                      alt="Post"
                      className="post-image"
                    />
                  )}
                  {selectedPost.video_url && (
                    <video controls className="post-video">
                      <source
                        src={`${baseUrl}${selectedPost.video_url}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              )}
              {posts.map((post) => (
                <div
                  className="sidebar-item"
                  key={post.ID}
                  onClick={() => handleMarkerClick(post.ID)}
                >
                  <div className="header">
                    <span className="place">
                      {highlightText(post.Place, searchTerm)}
                    </span>
                    |
                    <span className="keyword">
                      {highlightText(post.Keyword, searchTerm)}
                    </span>
                  </div>
                  <div className="executing-party">
                    {highlightText(post.ExecutingParty, searchTerm)}
                  </div>
                  {post.photo_url && (
                    <img
                      src={`${baseUrl}${post.photo_url}`}
                      alt="Post"
                      className="post-image"
                    />
                  )}
                  {post.video_url && (
                    <video controls className="post-video">
                      <source
                        src={`${baseUrl}${post.video_url}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <div className="operation-description">
                    {highlightText(post.OperationDescription, searchTerm)}
                  </div>
                  <div className="publication-date">
                    {highlightText(post.PublicationDate, searchTerm)}
                  </div>
                </div>
              ))}
              {hasMore && !isLoading && (
                <div className="load-more-container">
                  <button className="load-more-button" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
              {isLoading && <div className="loader">Loading...</div>}
            </div>
          )}
          {view === "filters" && (
            <div className="sidebar-filters">
              <FilterComponent />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
