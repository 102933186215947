import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MapComponent.css";
import L from "leaflet";
import Sidebar from "./Sidebar";
import MarkerClusterGroup from "react-leaflet-cluster";
import Select from "react-select";
import { useSearch } from "./SearchContext";
import { useSelectedPost } from "./SelectedPostContext";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useFilters } from "./FilterContext";
import FadeLoader from "react-spinners/FadeLoader";
import useMediaQuery from "@mui/material/useMediaQuery";



const MapComponent = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const initialZoom = window.innerWidth <= 768 ? 6 : 7.4;
  const { markerId } = useSelectedPost();
  const { searchTerm } = useSearch();
  const [locations, setLocations] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    selectedKeyword,
    selectedParty,
    startDate,
    endDate,
    selectedMediaType,
  } = useFilters();


  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);
  const markerRefs = useRef({}); // To store references of markers

  // Fetch locations based on filters
  const fetchLocations = async () => {
    try {
      setLoading(true);
      let url = "https://gazamap-backend.cogdevelopers.com/api/gaza-posts-map?";

      if (selectedKeyword) {
        url += `&keyword_id=${selectedKeyword}`;
      }

      if (selectedParty) {
        url += `&executing_party_id=${selectedParty}`;
      }

      if (searchTerm) {
        url += `&search=${searchTerm}`;
      }


      if (startDate) {
        url += `&start_date=${startDate.toISOString().split("T")[0]}`;
      }

      if (endDate) {
        url += `&end_date=${endDate.toISOString().split("T")[0]}`;
      }

      if (selectedMediaType) {
        url += `&media_type=${selectedMediaType}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      setLocations(data);
      
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // useEffect to trigger fetching locations
  useEffect(() => {
    fetchLocations();
  }, [selectedKeyword, selectedParty, searchTerm,startDate,endDate,selectedMediaType]);

  







 

  const handleSelectPost = (id) => {
    if (selectedPost !== id) {
      setSelectedPost(id);
    }
  };

  const handleMouseOver = (e) => {
    e.target.openPopup();
  };

  const handleMouseOut = (e) => {
    e.target.closePopup();
  };

  useEffect(() => {
    if (locations.length > 0) {
      setLoading(false);
    }
  }, [locations]);

  // Handle markerId change and pan to the selected marker
  useEffect(() => {
    if (markerId && locations.length > 0 && markerClusterRef.current) {
      const selectedLocation = locations.find((loc) => loc.ID === markerId);
      if (selectedLocation && mapRef.current) {
        const { Latitude, Longitude } = selectedLocation;
        const lat = parseFloat(Latitude);
        const lng = parseFloat(Longitude);
  
        // Pan the map to the selected marker
        mapRef.current.setView([lat, lng], 15);
  
        // Delay the zoom to show layer a bit if needed
        const marker = markerRefs.current[markerId];
        if (marker) {
          setTimeout(() => {
            markerClusterRef.current.zoomToShowLayer(marker, () => {
              marker.openPopup();
            });
          }, 300); // adjust delay as needed
        }
      }
    }
  }, [markerId]);
  

  // Memoize markers to prevent unnecessary re-renders
  const markers = useMemo(() => {
    return locations.map((location) => {
      const customIcon = L.icon({
        iconUrl: `https://gazamap-backend.cogdevelopers.com${location.ImageURL}`,
        iconSize: [55, 55],
        iconAnchor: [12, 41],
        popupAnchor: [13, -24],
      });

 

      return (
        <Marker
          key={location.ID}
          position={[parseFloat(location.Latitude), parseFloat(location.Longitude)]}
          icon={customIcon}
          eventHandlers={{
            mouseover: handleMouseOver,
            mouseout: handleMouseOut,
            click: () => handleSelectPost(location.ID),
            touchstart: (e) => {
              e.target.openPopup(); // Open the popup on touch
            },
            touchend: (e) => {
              e.target.closePopup(); // Close the popup when touch ends
            },
            touchcancel: (e) => {
              e.target.closePopup(); // Close the popup if the touch is canceled (e.g., the finger moves away)
            },
          }}
          ref={(ref) => {
            if (ref) {
              markerRefs.current[location.ID] = ref;
            }
          }}
        >
          <Popup className="popup" closeButton={false}>
            <div className="popup__wrapper">
              <span className="popup__name">{location.Place}</span> |{" "}
              <span className="popup__keyword">{location.Keyword}</span>
            </div>
            <div className="popup__wrapper">
              <span className="popup__executingParty">{location.ExecutingParty}</span>
            </div>
            <div className="popup__wrapper">
              <span className="popup__description">{location.OperationDescription}.....</span>
            </div>
            <div>
              <span className="popup__time">{location.PublicationDate}</span>
            </div>
          </Popup>
        </Marker>
      );
    });
  }, [locations]);

  return (
    <div className="map-wrapper">
       {loading && (
        <div className="loader-overlay">
          <FadeLoader color="#36d7b7" loading={loading} />
          <p>Loading map data, please wait...</p>
        </div>
      )}
      
     
     <Sidebar
          selectedKeyword={selectedKeyword}
          selectedParty={selectedParty}
          selectedPostId={selectedPost}
           startDate={startDate}
          endDate={endDate}
          selectedMediaType={selectedMediaType}
          onRemoveSelectedPost={() => setSelectedPost(null)}
        />
     

      <div className="loader-map">
          <FadeLoader color="#36d7b7" loading={loading} /> {/* Adjust color as needed */}
          <p>Loading data, please wait...</p>
        </div>

      {/* Map Container */}
      <MapContainer
        center={[31.5, 34.47]}
        zoom={initialZoom}
        style={{ height: "100%", width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; OpenStreetMap contributors'
        />
        <MarkerClusterGroup
          maxClusterRadius={60}
          spiderfyDistanceMultiplier={1.5}
          ref={markerClusterRef}
        >
          {markers}
        </MarkerClusterGroup>

   
      </MapContainer>
    </div>
  );
};

export default MapComponent;
