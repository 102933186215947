// src/Components/FilterContext.js

import React, { createContext, useState, useContext } from 'react';

// Create the context
const FilterContext = createContext();

// Create a provider component
export const FilterProvider = ({ children }) => {
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [selectedParty, setSelectedParty] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState(null)

  return (
    <FilterContext.Provider
      value={{
        selectedKeyword,
        setSelectedKeyword,
        selectedParty,
        setSelectedParty,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedMediaType,
        setSelectedMediaType,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook for easy access to the context
export const useFilters = () => useContext(FilterContext);
